<template>
  <Layout>
    <section class="csms-plan">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Summary Start -->
      <div class="row">
        <div class="col-xl-4">
          <SummaryCardWithSlot :value="infoItems.todayPaymentCount" :unit="$t('unit.case').toString()">
            <template v-slot:title>
              <span class="text-black-50 pe-1">{{ $t('common.today') }}</span>
              <span class="fw-bold">{{ $t('plan.paymentCount') }}</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-credit-card-outline font-size-26" />
            </template>
          </SummaryCardWithSlot>
        </div>
        <div class="col-xl-4">
          <SummaryCardWithSlot :value="infoItems.todayPaymentAmt" :unit="$t('unit.money').toString()">
            <template v-slot:title>
              <span class="text-black-50 pe-1">{{ $t('common.today') }}</span>
              <span class="fw-bold">{{ $t('plan.paymentPrice') }}</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-calculator font-size-26" />
            </template>
          </SummaryCardWithSlot>
        </div>
        <div class="col-xl-4">
          <SummaryCardWithSlot :value="infoItems.monthlyPaymentCount" :unit="$t('unit.person').toString()">
            <template v-slot:title>
              <span class="text-black-50 pe-1">{{ $t('common.lastMonth') }}</span>
              <span class="fw-bold">{{ $t('plan.planJoinCount') }}</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-account-plus font-size-26" />
            </template>
          </SummaryCardWithSlot>
        </div>
      </div>
      <!-- Summary End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3">
                      <label class="form-label" for="plan-history-search-tid">{{ $t('plan.tid') }}</label>
                      <input type="text" class="form-control" id="plan-history-search-tid"
                             v-model="searchForm.tid" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="plan-history-search-id">{{ $t('plan.email') }}</label>
                      <input type="text" class="form-control" id="plan-history-search-id"
                             v-model="searchForm.email" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="plan-history-search-period">{{ $t('plan.authPeriod') }}</label>
                      <div>
                        <date-picker class="w-100" v-model="searchForm.period" type="date" range
                                     :placeholder="$t('alert.selectDateRange')" :clearable="false" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('plan.noHistory')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`plan.${data.column}`) }}</span>
                  </template>

                  <template #cell(memberName)="data">
                    {{ !$_utils.isEmpty(data.item.memberName) ? data.item.memberName : '-' }}
                  </template>
                  <template #cell(amt)="data">
                    {{ !$_utils.isEmpty(data.item.amt) ? $_utils.comma(data.item.amt) : '-' }}
                  </template>
                  <template #cell(paymentName)="data">
                    {{ data.item.appCardName }}
                  </template>
                  <template #cell(paymentAuthDate)="data">
                    {{ data.item.authDate }}
                  </template>
                  <template #cell(planDate)="data">
                    {{ data.item.startDate }}
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import SummaryCardWithSlot from '@/views/components/SummaryCardwithSlot.vue';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Layout, PageHeader, PerPageSelector, TotalCount, SummaryCardWithSlot, Multiselect,
  },
  data() {
    return {
      title: 'planHistory',
      infoItems: {
        todayPaymentCount: 0,
        todayPaymentAmt: 0,
        monthlyPaymentCount: 0
      },
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        tid: null,
        email: null,
        period: null
      },
      fields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'tid', label: '결제 ID', class: 'text-center' },
        { key: 'planName', label: '플랜명', class: 'text-center' },
        { key: 'email', label: '아이디', class: 'text-center' },
        { key: 'memberName', label: '이름', class: 'text-center' },
        { key: 'amt', label: '결제 금액', class: 'text-center' },
        { key: 'paymentName', label: '결제 수단', class: 'text-center' },
        { key: 'paymentAuthDate', label: '결제 승인시각', class: 'text-center' },
        { key: 'planDate', label: '플랜 시작일', class: 'text-center' }
      ],
      items: null
    }
  },
  mounted() {
    const vm = this;

    vm.getInfoItems();
    vm.getListItems();
  },
  methods: {
    ...loadingMethods,
    getInfoItems() {
      const vm = this;
      const _url = '/onmapi/view/Summary/planPayment';

      http.get(_url).then(res => {
        const _item = res.data.item;
        vm.infoItems = {
          todayPaymentCount: _item.todayPaymentCount,
          todayPaymentAmt: _item.todayPaymentAmt,
          monthlyPaymentCount: _item.monthlySubscribeCount
        };
      }).catch(err => {
        console.error('Plan History Info Error :: ', err)
      });
    },
    reset() {
      const vm = this;

      vm.searchForm = {
        tid: null,
        email: null,
        period: null
      };
      vm.searchConditions = [];

      vm.getListItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.tid)) {
        vm.searchConditions.push({ searchKey: 'tid', searchValue: vm.searchForm.tid });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.email)) {
        vm.searchConditions.push({ searchKey: 'email', searchValue: vm.searchForm.email });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.period)) {
        vm.searchConditions.push({ searchKey: 'authStartDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD') });
        vm.searchConditions.push({ searchKey: 'authEndDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD') });
      }

      vm.pagination.currentPage = 1;
      vm.getListItems();
    },
    async getListItems() {
      const vm = this;
      const _url = '/onmapi/view/PlanPayment/list';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
        vm.hideOverlay();
      }).catch(err => {
        console.error('Plan Payment History List Error :: ', err)
        vm.hideOverlay();
      });
    },
    onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.getListItems();
      }
    },
    onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.getListItems();
      }
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
